import { FC, Fragment, useCallback, useContext } from 'react';

import { css } from '@emotion/react';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  CatalogItemType,
  ListItemType,
  ListItemUpdateInput,
  TradeSkuPrice,
  WholesalePreOrderItem
} from 'generated-types.d';

import { CurrencyService } from 'lib';

import SelectedWholesalePreOrderStore from 'stores/selected-wholesale-pre-order/selected-wholesale-pre-order-store';
import ToasterStore from 'stores/toaster-store/toaster-store';

import { breakpoints } from 'utils/rebass-theme';

import { getListItemIncrement } from 'features/lists/components/checkout-list/checkout-list.helpers';
import { TotalPriceRange } from 'features/lists/components/checkout-list/checkout-list.styles';
import { ListItemValue } from 'features/lists/components/list-item-value/list-item-value';
import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import { ListItemMaxUnitPriceField } from 'features/lists/components/max-unit-price-field/max-unit-price-field';
import { getItemTotalPrice, getItemUnitPrice, listItemSkuPrices } from 'features/lists/lists.helpers';
import { DisabledItem } from 'features/lists/lists.styles';
import { ListFieldConfig, ListLayoutConfig } from 'features/lists/lists.types';

import QuantitySelector from 'components/quantity-selector';

import { PreOrderDefaultTitle, PreOrderPromotedTitle } from './components/single-pre-order-title';
import { SingleWholesalePreOrderContext } from './single-pre-order.context';

const PreOrderItemQuantitySelector: FC<{
  preOrderItem: WholesalePreOrderItem;
  selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
  toasterStore?: ToasterStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore,
  toasterStore: stores.toasterStore
}))(observer(({
  preOrderItem,
  selectedWholesalePreOrderStore,
  toasterStore
}) => {
  const increment = getListItemIncrement(preOrderItem.listItem);
  const isLoading = selectedWholesalePreOrderStore!.itemIdsBeingEdited.has(preOrderItem.id);

  const handleUpdateQuantity = async (value: number): Promise<void> => {
    if (!selectedWholesalePreOrderStore!.preOrder) return;

    try {
      await selectedWholesalePreOrderStore!.updatePreOrderItem({
        preOrderId: selectedWholesalePreOrderStore!.preOrder.id,
        preOrderItemId: preOrderItem.id,
        updateInput: {
          listItem: {
            update: {
              quantity: value
            }
          }
        }
      });

      await selectedWholesalePreOrderStore!.fetchPreOrderTotals({ preOrderId: selectedWholesalePreOrderStore!.preOrder.id });
    } catch (error) {
      toasterStore!.popErrorToast('the quantity for this pre-order item', 'update');
    }
  };

  const debouncedChangeHandler = useCallback(debounce(handleUpdateQuantity, 400), []);

  return (
    <Box width={130}>
      <QuantitySelector
        step={increment}
        min={increment}
        initialValue={preOrderItem.listItem.quantity || 0}
        isDisabled={isLoading}
        onChange={debouncedChangeHandler}
      />
    </Box>
  );
}));

const smallScreenWidthBaseStyles = css`
  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 50%;
  }

  @media only screen and (max-width: ${breakpoints.small}) {
    width: 100%;
  }`
;

const ITEM_NAME_GENUS_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'itemName',
  heading: 'Item/Genus',
  renderValue: item => (<PreOrderDefaultTitle item={item} />)
};

const ITEM_NAME_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'itemName',
  heading: 'Item',
  renderValue: item => (<PreOrderPromotedTitle item={item} />)
};
const STEM_LENGTH_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'stemLength',
  heading: 'Stem length',
  customStyles: smallScreenWidthBaseStyles,
  supportedTypes: [
    CatalogItemType.Flower,
    ListItemType.Flower
  ],
  renderValue: ({ listItem }) => {
    const value = ((): string | null => {
      switch (listItem?.__typename) {
        case 'ListItemTypeFlower':
          const hasStemLength = !!listItem?.minimumStemLength && listItem?.stemLengthUnit;

          return hasStemLength ? `${listItem.minimumStemLength}${listItem.stemLengthUnit}` : null;

        default:
          return null;
      }
    })();

    return (
      <ListItemValue
        label="Stem length"
        value={value || 'Any'}
      />
    );
  }
};

const POT_SIZE_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'potSize',
  heading: 'Pot size',
  customStyles: smallScreenWidthBaseStyles,
  supportedTypes: [
    CatalogItemType.Plant,
    ListItemType.Plant
  ],
  renderValue: ({ listItem }) => {
    const value = ((): string | null => {
      switch (listItem?.__typename) {
        case 'ListItemTypePlant':
          return listItem?.potSize || null;

        default:
          return null;
      }
    })();

    return (
      <ListItemValue
        label="Pot size"
        value={value || 'Any'}
      />
    );
  }
};

const WEIGHT_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'weight',
  heading: 'Weight',
  customStyles: smallScreenWidthBaseStyles,
  supportedTypes: [
    CatalogItemType.Sundry,
    ListItemType.Sundry
  ],
  renderValue: ({ listItem }) => {
    const value = ((): string | null => {
      switch (listItem?.__typename) {
        case 'ListItemTypeSundry':
          return listItem?.weight || null;

        default:
          return null;
      }
    })();

    return (
      <ListItemValue
        label="Weight"
        value={value || 'Any'}
      />
    );
  }
};

const HEIGHT_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'height',
  heading: 'Height',
  customStyles: smallScreenWidthBaseStyles,
  supportedTypes: [
    CatalogItemType.Plant,
    ListItemType.Plant,
    CatalogItemType.Sundry,
    ListItemType.Sundry
  ],
  renderValue: ({ listItem }) => {
    const value = ((): string | null => {
      switch (listItem?.__typename) {
        case 'ListItemTypePlant':
        case 'ListItemTypeSundry':
          return listItem?.height || null;

        default:
          return null;
      }
    })();

    return (
      <ListItemValue
        label="Height"
        value={value || 'Any'}
      />
    );
  }
};

const MATURITY_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'maturity',
  heading: 'Maturity',
  customStyles: smallScreenWidthBaseStyles,
  supportedTypes: [
    CatalogItemType.Flower,
    ListItemType.Flower
  ],
  renderValue: ({ listItem }) => {
    const value = ((): string | null => {
      switch (listItem?.__typename) {
        case 'ListItemTypeFlower':
          return listItem.maturity || null;

        default:
          return null;
      }
    })();

    return (
      <ListItemValue
        label="Maturity"
        value={value || 'Any'}
      />
    );
  }
};

const QUANTITY_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'quantity',
  heading: 'Quantity',
  customStyles: css`
    width: 20%;

    @media only screen and (max-width: ${breakpoints.medium}) {
      width: 100%;
      order: 2;
    }
  `,
  renderValue: item => {
    const Component: FC<{
      selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
    }> = inject((stores: FxStores) => ({
      selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
    }))(observer(() => {
      const {
        isEditingPreOrder
      } = useContext(SingleWholesalePreOrderContext);

      switch (true) {
        case !!isEditingPreOrder: {
          return (
            <div>
              <PreOrderItemQuantitySelector preOrderItem={item} />
            </div>
          );
        }

        default: {
          return (
            <Box pr="10px">
              {item.listItem?.quantity || '--'}
            </Box>
          );
        }
      }
    }));

    return (
      <ListItemValue
        label="Quantity"
        value={<Component />}
      />
    );
  }
};

const ESTIMATED_PRICE_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'estimatedPrice',
  heading: 'Est. price',
  customStyles: css`
    width: 15%;

    @media only screen and (max-width: ${breakpoints.medium}) {
      order: 3;
      width: 100%;
      text-align: right;
    }
  `,
  renderValue: item => {
    const Component: FC<{
      selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
    }> = inject((stores: FxStores) => ({
      selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
    }))(observer(({
      selectedWholesalePreOrderStore
    }) => {
      const allPrices = listItemSkuPrices(item?.listItem);
      const isLoading = selectedWholesalePreOrderStore!.itemIdsBeingEdited.has(item?.listItem.id);
      const merchantCurrency = selectedWholesalePreOrderStore!.preOrder?.merchant?.currency;

      switch (true) {
        case item?.listItem.type === ListItemType.Custom: {
          const priceSubtotal = item?.listItem.maxUnitPrice ? item?.listItem.maxUnitPrice * (item?.listItem.quantity || 1) : undefined;

          return (
            <DisabledItem
              css={css`
                opacity: ${isLoading ? .4 : 1}
              `}
            >
              <TotalPriceRange>
                <ListPriceRange
                  min={priceSubtotal}
                  max={priceSubtotal}
                  currency={merchantCurrency}
                />
              </TotalPriceRange>
              <Fragment>
                <ListPriceRange
                  min={item?.listItem.maxUnitPrice || undefined}
                  max={item?.listItem.maxUnitPrice || undefined}
                  currency={merchantCurrency}
                />
                {' '}
                <span>
                  /unit
                </span>
              </Fragment>
            </DisabledItem>
          );
        }

        case !!allPrices?.length: {
          const allPricesSingle = allPrices!.map(price => price.price);
          const allPricesSubtotal = allPrices!.map(price => price.price * (item?.listItem.quantity || 1));

          const subtotalPriceMin = getItemTotalPrice(item?.listItem, allPricesSubtotal, Math.min);
          const subtotalPriceMax = getItemTotalPrice(item?.listItem, allPricesSubtotal, Math.max);
          const singlePriceMin = getItemUnitPrice(item?.listItem, allPricesSingle, Math.min);
          const singlePriceMax = getItemUnitPrice(item?.listItem, allPricesSingle, Math.max);

          return (
            <DisabledItem
              css={css`
                opacity: ${isLoading ? .4 : 1}
              `}
            >
              <TotalPriceRange>
                <ListPriceRange
                  min={subtotalPriceMin}
                  max={subtotalPriceMax}
                  currency={merchantCurrency}
                />
              </TotalPriceRange>
              <Fragment>
                <ListPriceRange
                  min={singlePriceMin}
                  max={singlePriceMax}
                  currency={merchantCurrency}
                />
                {' '}
                <span>
                  /unit
                </span>
              </Fragment>
            </DisabledItem>
          );
        }

        default:

        case !allPrices?.length: {
          return <DisabledItem>--</DisabledItem>;
        }
      }
    }));

    return (
      <Component />
    );
  }
};

const MAX_UNIT_PRICE_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'maxUnitPrice',
  heading: 'Max unit price',
  customStyles: css`
    width: 15%;
    min-width: 90px;

    @media only screen and (max-width: ${breakpoints.medium}) {
      width: 100%;
      order: 1;
      align-items: center;
    }
  `,
  renderValue: item => {
    const Component: FC<{
      selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
      toasterStore?: ToasterStore;
    }> = inject((stores: FxStores): InjectedFxStores => ({
      selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore,
      toasterStore: stores.toasterStore
    }))(observer(({
      selectedWholesalePreOrderStore,
      toasterStore
    }) => {
      const allPrices = listItemSkuPrices(item?.listItem);
      const {
        isEditingPreOrder
      } = useContext(SingleWholesalePreOrderContext);

      const updatePreOrderItem = useCallback(async (data: ListItemUpdateInput): Promise<void> => {
        if (!selectedWholesalePreOrderStore!.preOrder) return;

        try {
          await selectedWholesalePreOrderStore!.updatePreOrderItem({
            preOrderId: selectedWholesalePreOrderStore!.preOrder.id,
            preOrderItemId: item.id,
            updateInput: {
              listItem: {
                update: data
              }
            }
          });
        } catch (error) {
          toasterStore!.popErrorToast('the max unit price for this pre-order item', 'update');
        }
      }, []);

      switch (true) {
        case (isEditingPreOrder && !!item.listItem): {
          const allPricesInts = allPrices!.map(price => price.price);
          const max = Math.max(...(allPricesInts?.length ? allPricesInts : [0.1]));

          return (
            <ListItemMaxUnitPriceField
              listItem={item.listItem}
              maxUnitPrice={item.listItem.maxUnitPrice}
              maxAvailablePrice={max}
              onUpdateListItem={updatePreOrderItem}
              currency={selectedWholesalePreOrderStore!.preOrder!.merchant?.currency}
              placeholder={`${max.toFixed(2)}`}
            />
          );
        }

        default:

        case !item?.listItem?.maxUnitPrice: {
          return <DisabledItem>Not set</DisabledItem>;
        }

        case !!item?.listItem?.maxUnitPrice: {
          return (
            <Fragment>
              {CurrencyService.formatPrice(item.listItem?.maxUnitPrice || 0, selectedWholesalePreOrderStore!.preOrder!.merchant.currency)}
            </Fragment>
          );
        }
      }
    }));

    return (
      <ListItemValue
        label="Set max unit price"
        value={<Component />}
      />
    );
  }
};

const PRICE_FIELD: ListFieldConfig<WholesalePreOrderItem> = {
  key: 'price',
  heading: 'Price',
  customStyles: css`
    width: 15%;

    @media only screen and (max-width: ${breakpoints.medium}) {
      order: 3;
      width: 100%;
      text-align: right;
    }
  `,
  renderValue: item => {
    const Component: FC<{
      selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
    }> = inject((stores: FxStores) => ({
      selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
    }))(observer(({
      selectedWholesalePreOrderStore
    }) => {
      const allPrices = item?.listItem.type === ListItemType.Custom && item?.listItem.maxUnitPrice
        ? [{ price: item.listItem.maxUnitPrice } as TradeSkuPrice]
        : listItemSkuPrices(item?.listItem);
      const isLoading = selectedWholesalePreOrderStore!.itemIdsBeingEdited.has(item?.listItem.id);

      if (!allPrices?.length) {
        return <DisabledItem>--</DisabledItem>;
      }

      const allPricesSingle = allPrices!.map(price => price.price);
      const allPricesSubtotal = allPrices!.map(price => price.price * (item?.listItem.quantity || 1));

      const subtotalPriceMin = getItemTotalPrice(item?.listItem, allPricesSubtotal, Math.min);
      const subtotalPriceMax = getItemTotalPrice(item?.listItem, allPricesSubtotal, Math.max);
      const singlePriceMin = getItemUnitPrice(item?.listItem, allPricesSingle, Math.min);
      const singlePriceMax = getItemUnitPrice(item?.listItem, allPricesSingle, Math.max);

      const merchantCurrency = selectedWholesalePreOrderStore?.preOrder?.merchant?.currency;

      return (
        <DisabledItem
          css={css`
            opacity: ${isLoading ? .4 : 1}
          `}
        >
          <TotalPriceRange>
            <ListPriceRange
              min={subtotalPriceMin}
              max={subtotalPriceMax}
              currency={merchantCurrency}
            />
          </TotalPriceRange>
          <Fragment>
            <ListPriceRange
              min={singlePriceMin}
              max={singlePriceMax}
              currency={merchantCurrency}
            />
            {' '}
            <span>
              /unit
            </span>
          </Fragment>
        </DisabledItem>
      );
    }));

    return (
      <Component />
    );
  }
};

export const SINGLE_PRE_ORDER_LIST_FIELD_CONFIG: ListLayoutConfig<WholesalePreOrderItem> = {
  Default: {
    titleField: ITEM_NAME_GENUS_FIELD,
    fields: [
      STEM_LENGTH_FIELD,
      POT_SIZE_FIELD,
      WEIGHT_FIELD,
      HEIGHT_FIELD,
      MATURITY_FIELD,
      QUANTITY_FIELD,
      ESTIMATED_PRICE_FIELD,
      MAX_UNIT_PRICE_FIELD
    ]
  },
  Promoted: {
    titleField: ITEM_NAME_FIELD,
    fields: [
      STEM_LENGTH_FIELD,
      POT_SIZE_FIELD,
      WEIGHT_FIELD,
      MATURITY_FIELD,
      QUANTITY_FIELD,
      PRICE_FIELD
    ]
  }
};
